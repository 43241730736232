export const AVAILABLE_FILTERS = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "Human",
  },
  {
    id: 3,
    name: "Animal",
  },
  {
    id: 4,
    name: "Alien",
  },
];
